li {
  list-style: none;
}

/* .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */

.header .headerText {
  font-size: 20px;
  font-family: circular-std-medium;
}

.text {
  font-size: 30px;
  font-family: circular-std-medium;
}
