/* Add this to your existing CSS file */
.section2 {
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;
  z-index: 1; /* Set z-index to 1 to bring the content above the global background */
}

.section2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  transform: skewY(-7deg);
  z-index: -1; /* Set z-index to -1 to place the skewed background behind the content */
}

.section2 .title {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 50px;
  /* font-weight: 600; */
  color: #0f0f0f;
  font-family: circular-std-bold;
  letter-spacing: 1px;
}

.container1 {
  padding-bottom: 130px;
}

@media (max-width: 425px) {
  .section2 {
    padding-top: 40px;
  }
  .section2::before {
    transform: skewY(0deg);
  }
  .section2 .title {
    font-size: 35px;
  }
  .section2 .container2 .title2 {
    font-size: 35px;
    margin-bottom: 2rem;
  }
}

/* Next Section */

.container2 .title2 {
  margin-bottom: 4rem;
  text-align: center;
  font-size: 50px;
  /* font-weight: 600; */
  color: #0f0f0f;
  font-family: circular-std-bold;
  letter-spacing: 1px;
}

.container2 .title2 .five {
  text-decoration: underline orangered;
}

.container2 h1 {
  font-size: 22px;
  text-align: center;
  color: #0f0f0e;
  font-family: "circular-std-bold";
}

.container2 p {
  text-align: center;
  line-height: 1.9;
  font-size: 20px;
  font-weight: 500;
  color: #535861;
  font-family: "circular-std-medium";
}
