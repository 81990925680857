.App {
  /* text-align: center; */
  background-color: #ffc91f;
  min-height: 100vh;
  /* z-index: 0; */
}

@font-face {
  font-family: circular-std-bold;
  src: url("./headingFont.ttf");
}

@font-face {
  font-family: circular-std-light;
  src: url("./CircularStd-Light.otf");
}

@font-face {
  font-family: circular-std-medium;
  src: url("./CircularStd-Medium.otf");
}
