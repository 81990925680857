.section3{
  padding-bottom: 40px;
  padding-top: 30px;
}

.section3 h2 {
  margin-bottom: 1rem;
  text-align: left;
  font-size: 36px;
  font-weight: 600;
  color: #0f0f0f;
  font-family: circular-std-bold;
}

.noDataFound {
  font-size: 30px;
  font-family: circular-std-medium;
}
