.urlText {
  color: "#0f0f0e";
  font-family: circular-std-medium;
  /* border: 2px solid #ffc91f; */
}

.drawerContent .inputAddon {
  background-color: #ffc91f;
}

.drawerContent .insideText {
  font-family: circular-std-light;
  outline: none;
}

label,
.header {
  font-family: circular-std-bold;
}

.drawerContent .submitBtn {
  background-color: #ffc91f;
}

.drawerContent .submitBtn:hover {
  background-color: #fad456;
}
