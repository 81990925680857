.section4 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.section4 .card button {
  padding: 20px 10px;
  /* margin: 10px; */
  background-color: transparent;
  border: 2px solid #0f0f0e;
  font-family: "circular-std-light";
  color: #0f0f0e;
  transition: 0.6s;
}

.section4 .card button a {
  padding: 10px 10px;
}

.section4 .card button:active {
  transform: scale(0.8);
  transition: 0.4s;
}

.section4 .title {
  text-align: center;
  font-size: 50px;
  color: #0f0f0f;
  font-family: circular-std-bold;
  padding-bottom: 30px;
  letter-spacing: 1px;
}

.section4 .title .tagLine {
  font-family: circular-std-light;
  font-size: 35px;
  font-weight: 500;
  color: rgb(23, 23, 23);
}

.text {
  color: #0f0f0e;
  font-family: circular-std-bold;
}

@media (max-width: 435px) {
  .section4 .title .tagLine {
    font-size: 26px;
    font-family: circular-std-medium;
  }
}
