.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 4rem;
  padding-top: 40px;
}

.innerNavbar {
  display: flex;
  justify-content: space-between;
  width: 80%;
  /* background-color: #ffd13a; */
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
}

.logoAndLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.navbar .image {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.navbar .image h2 {
  font-weight: 700;
  font-size: 30px;
  color: #0f0f0e;
  font-family: circular-std-bold;
}

.navbar .image .websiteName {
  color: #ffc91f;
  -webkit-text-stroke: 1.2px #0f0f0e;
}

.navbar .links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  color: #0f0f0f;
}

.navbar .links li {
  list-style: none;
  font-family: circular-std-medium;
  font-size: 20px;
}

.navbar .action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.navbar .action img {
  border-radius: 50%;
}

.navbar .action button {
  border: 2px solid black;
  padding: 9px 21px;
  border-radius: 4px;
  font-size: 16px;
  color: #0f0f0e;
  font-family: circular-std-medium;
}

.navbar .action button:hover {
  background-color: #0f0f0e;
  color: white;
}

.navbar .ham {
  display: none;
}

.navbar .ham .user {
  margin-right: 0.5rem;
  border-radius: 50%;
}

.navbar .ham .hamburgerBtn {
  background-color: black;
  border-radius: 7px;
  width: 90%;
}

.hamburgerOptions {
  padding: 20px;
  background-color: #0f0f0e;
  color: white;
  text-align: center;
  border-radius: 7px;
  margin-bottom: 20px;
  font-size: 20px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex-direction: column;
}

.actions .user {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1.2rem;
}

.actions .user img {
  border-radius: 50%;
}

.actions .user button {
  border: 2px solid black;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 16px;
  color: #0f0f0e;
  font-family: circular-std-medium;
}

.actions .user button:hover {
  background-color: #0f0f0e;
  color: white;
}

@media (max-width: 865px) {
  .navbar .links,
  .navbar .action {
    display: none;
  }

  .navbar .ham {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innerNavbar {
    width: 92%;
  }
}
