/* Your Shortcut to Easy Sharing and Snappy URLs! */
@import url("https://fonts.googleapis.com/css2?family=Signika+Negative:wght@500&display=swap");

.section1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 100px;
}

.section1 h3 {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: left;
  color: #0f0f0f;
  font-family: circular-std-bold;
  /* font-family: "Signika Negative", sans-serif; */
  margin-bottom: 33px;
}

.section1 p {
  font-family: circular-std-medium;
  text-align: left;
  font-size: 25px;
  line-height: 40px;
  color: #0f0f0e;
}

@media (max-width: 425px) {

  .section1 {
    padding-bottom: 70px;
  }
  .section1 h3 {
    text-align: left;
    font-size: 40px;
    line-height: 49px;
  }
  .section1 p {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
  }
}
