.text {
  font-size: 18px;
  font-family: circular-std-light;
}

.header {
  font-size: 20px;
  font-family: circular-std-medium;
}

@media (max-width: 768px) {
  .text {
    font-size: 18px;
  }

  .header {
    font-size: 20px;
  }
}

@media (max-width: 435px) {
  .text {
    font-size: 14px;
  }

  .header {
    font-size: 17px;
  }
}
