.headerText {
  position: absolute;
  top: 10;
  font-size: 20px;
  font-family: circular-std-medium;
}

.text {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-family: circular-std-medium;
}
